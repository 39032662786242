<template>
  <div class="w_material_detail">
    <div class="left_com">
      <div class="top">
        <div class="title">
          <span class="text">{{ data.dto.en_title }}</span>
          <span class="btn en" v-if="data.dto.favourite_status==0"  @click="collect(1)">
            <img src="../../../assets/images/cmf_chouc.png" />
            <span>Favorite</span>
          </span>
          <span class="btn en" v-if="data.dto.favourite_status == 1" @click="collect(0)">
            <img src="../../../assets/images/star.png" />
            <span>Favoriteed</span>
          </span>
        </div>
      

        
<!-- 
        <el-carousel class="imgbox" height="233px">
          <el-carousel-item v-for="(item, index) in data.dto.albums" :key="index">
            <el-image class="img" fit="cover" :src="_this.utils.HandleUrl(item.original_path, false, 1)">
            </el-image>
          </el-carousel-item>
        </el-carousel> -->

         <el-carousel class="imgbox" height="233px">
          <el-carousel-item v-for="(item, index) in data.dto.albums" :key="index">
            <viewer :images="data.dto.albums">
                  <img  :src="item.original_path"  height="100">
            </viewer>
          </el-carousel-item>
        </el-carousel>

       
        
      </div>

      <div class="bot">
        <div class="desc"><span>Basic material properties</span></div>
        <div class="descCon">
            <table border="0" cellspacing="1" cellpadding="4px" width="100%">
                <tbody>
                  <template v-for="(item, index) in data.dto.en_property_data">
                    <tr :key="index" v-if="item.field_type == 1">
                      <td align="left" valign="middle">{{ item.field_name }}:</td>
                      <td align="left" valign="middle">{{ item.value }}</td>
                    </tr>
                  </template>
              </tbody>
            </table>
        </div>
      </div>

      <div class="bot" v-if="data.dto.content">
        <div class="desc"><span>Detailed introduction</span></div>
        <div class="descCon" v-html="data.dto.en_content"></div>
      </div>
    </div>
    <div class="right_com">
    
      <div class="lingyu">
        <div class="title border">Purchasing attributes</div>
        <div class="design">
           <table border="0" cellspacing="1" cellpadding="4px" width="100%">
                <tbody>
                    <template v-for="(item, index) in data.dto.en_property_data">
                        <tr :key="index" v-if="item.field_type == 3">
                           <td align="left" valign="middle">{{ item.field_name }}:</td>
                           <td align="left" valign="middle">{{ item.value }}</td>
                        </tr>
                    </template>
                </tbody>
           </table>
        </div>
      </div>

      <div class="lingyu">
        <div class="title border">Environmental reliability test</div>
        <div class="design">
           <table border="0" cellspacing="1" cellpadding="4px" width="100%">
                <tbody>
                    <template v-for="(item, index) in data.dto.en_property_data">
                        <tr :key="index" v-if="item.field_type == 2">
                           <td align="left" valign="middle">{{ item.field_name }}:</td>
                           <td align="left" valign="middle">{{ item.value }}</td>
                        </tr>
                    </template>
                </tbody>
           </table>
        </div>
      </div>
      <!-- <div class="lingyu">
        <div class="title border">设计指导</div>
        <div class="design">
          <div class="item">名称：{{ data.dto.title }}</div>
          <div class="item">来源：{{ data.dto.material_source ? data.dto.material_source : '--' }}</div>
          <div class="item">材料编码：{{ data.dto.material_no ? data.dto.material_no:'--' }}</div>
          <div class="item">主要成分：{{ data.dto.main_ingredients ? data.dto.main_ingredients:'--' }}</div>
          <div class="item">材质特点：{{ data.dto.material_characteristics?'':'--' }}<span v-html="data.dto.material_characteristics"></span></div>
          <div class="item">适合产品：{{ data.dto.suitable_product ? data.dto.suitable_product:'--' }}</div>
          <div class="item">
            <div class="img_title">附件：</div>
            <el-image class="img_body" fit="cover" :src="data.fj_img.url" :zoom-rate="1.2" :preview-src-list="data.fj_img.srcList"><template #error>
                <div class="image-slot">
                  <el-icon><icon-picture /></el-icon>
                </div>
              </template>
            </el-image>

            <div class="pdf_body">
              <img src="../../assets/images/pdf.png" @click="DownloadPdf"/>
            </div>
          </div>
        </div>
      </div> -->
      <div class="lingyu">
        <div class="title">Case</div>
        <div class="list_anli">
          <template v-if="data.list_anli.length == 0">
            <div style="color:#1B243A;font-size: 13px;">--</div>
          </template>
          <template v-else>
            <router-link :to="{ name: 'enanlidetail', params: { id: item.id } }" class="item"
              v-for="(item, index) in data.list_anli" :key="index">
              <el-image class="img" fit="cover" :src="item.img_url">
                <template #error>
                  <div class="image-slot">
                    <el-icon><icon-picture /></el-icon>
                  </div>
                </template>
              </el-image>
              <div class="title">{{ item.en_title }}</div>
            </router-link>
          </template>
        </div>
      </div>
      <div class="lingyu">
        <div class="title">Inspection report</div>
        <div class="report">
          <template v-if="data.preview_src_list.length == 0">
            <div style="color:#1B243A;font-size: 13px;">--</div>
          </template>
          <template v-else>
            <el-image class="img" fit="cover" :src="item" :zoom-rate="1.2" :preview-src-list="data.preview_src_list"
              v-for="(item, index) in data.preview_src_list" :key="index"><template #error>
                <div class="image-slot">
                  <el-icon><icon-picture /></el-icon>
                </div>
              </template>
            </el-image>
          </template>
        </div>
      </div>
    </div>

    <div class="popForm en is-visible" v-if="data.pop_permission">
           <div class="pop_dialog">
                <div class="dialog_header">
                    <div class="dialog_title">Please enter a password to view!</div>
                </div>
                <div class="dialog_container">
                     <div class="label">View password</div>
                     <div class="dialog_input"><input type="password" v-model="data.password" placeholder="Please enter the password"></div>
                     <span class="error">{{data.errortip}}</span>
                </div>
                <div class="dialog_footer">
                    <a href="javascript:void(0);" class="dialog_button button_primary" @click="submit()">submit</a>
                </div>
           </div>
      </div>

  </div>
</template>

<script setup>
import Swiper from "swiper/swiper-bundle.min.js"
import "swiper/swiper-bundle.min.css"

import { useRoute, useRouter } from 'vue-router';
import { getCurrentInstance, reactive, nextTick } from 'vue';
import { Picture as IconPicture } from '@element-plus/icons-vue';
import { localStorage } from '../../../utils/storage';

const instance = getCurrentInstance();
const _this = instance.appContext.config.globalProperties;

const pwd_key = 'pwd_key';
const pwd_real= 'Ubo83814997';
const $route = useRoute();
const $router = useRouter();
const pro_id = $route.params.id;
const data = reactive({
  dto: {},
  password:'',
  errortip:'',
  preview_src_list: [],
  fj_img: {
    url: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
    srcList: [
      'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
      'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
      'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
      'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
      'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
      'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
      'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg',
    ]
  },
  list_anli: []
});
let list_yingyong = reactive([]); //应用

var has_permission=CheckPermission();
if (has_permission){
  GetMaterialDetail();
  GetAnliList();
}else{
  data.pop_permission=true;
}


function GetMaterialDetail() {
  var url = "/api/cailiaoguan.ashx?action=GetMaterialDetail";
  _this.$http.post(url, { id: pro_id }).then(function (res) {
    if (res.data.status == 1) {
      data.dto = res.data.data;

      res.data.data.material_props.lingyu.map(item => {
        if (item.selected) list_yingyong.push(item);
      })
      res.data.data.inspection_report.map(item => {
        let temp_src = item.original_path;
        data.preview_src_list.push(temp_src);
      });

      initSwiper();
    } else if (res.data.status == 3) {
      localStorage.remove('user_info');
      $router.push({ name: 'login' });
    } else {
      console.log(res);
    }
  }.bind(this)).catch(function () {
  });
}
function GetAnliList() {
  var url = "/api/cailiaoguan.ashx?action=GetAnliList";
  _this.$http.post(url, { id: pro_id }).then(function (res) {
    if (res.data.status == 1) {
      data.list_anli = res.data.data;
    }
  }.bind(this)).catch(function () {
  });
}

function collect(_status){
  var url = "/api/cailiaoguan.ashx?action=user_collection";
  _this.$http.post(url, { id: pro_id, status: _status }).then(function (res) {
    if (res.data.status == 1) {
      data.dto.favourite_status = _status;
    } else if (res.data.status == 3) {
      localStorage.remove('user_info');
      $router.push({ name: 'login' });
    }
  }.bind(this)).catch(function () {
  });
}


// function DownloadPdf(){
//   window.open('https://m.xincailiao.com/upload/202109/02/2021090211210129190.pdf');
// }

function initSwiper() {
  nextTick(() => {
    new Swiper("#swiper", {
      zoom: true,
      slidesPerView: 1,
      centeredSlides: true,
      loop: true,
      // autoplay: true,
      pagination: {
        el: '.swiper-pagination',
        // clickable: true,
        // type: 'fraction',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  });
}


function CheckPermission(){
    if (localStorage.get('user_info')) return true;
    var store_pwd = localStorage.get(pwd_key);
    console.log('store_pwd',store_pwd);
    if (store_pwd==pwd_real) return true;
    return false;
}

function submit(){
  if (!data.password){
    data.errortip='Please enter the password';
    return;
  }
  if (data.password!=pwd_real){
    data.errortip='Password error';
    return;
  }
  GetMaterialDetail();
  GetAnliList();
  data.pop_permission=false;
  localStorage.set(pwd_key,data.password,60*60*24*3);
}

</script>
